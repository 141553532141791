





































import { Namespace } from "@/store/types";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  SwitchboardAction,
  SwitchboardGetter,
  AssignSlotTrack,
  TrackSlotDraggable,
} from "../Switchboard/types";
import draggable from "vuedraggable";
import { Conference, SlotId, TrackSlot } from "@/spect8-core-vue/src/types";

@Component({
  components: { draggable },
})
export default class SwitchboardRoom extends Vue {
  @Prop({ required: true }) readonly conferenceId!: string;
  @Prop({ required: true }) readonly activeConferences!: Conference[];
  @Prop({ required: true }) readonly slotId!: SlotId;
  @Prop({ required: true }) readonly tenantId!: string;
  @Prop({ default: false }) readonly isSaved!: boolean;
  @Prop({ default: 0 }) readonly trackKind!: number;
  @Prop({ default: 0 }) readonly currentItemType!: number;

  @Action(`${Namespace.Switchboard}/${SwitchboardAction.AssignSlotTracks}`)
  assignSlotTracks!: (data: AssignSlotTrack) => Promise<void>;

  @Action(`${Namespace.Switchboard}/${SwitchboardAction.PushSlotTrack}`)
  pushSlotTrack!: (trackSlot: TrackSlotDraggable) => Promise<void>;

  @Action(`${Namespace.Switchboard}/${SwitchboardAction.DeleteSlotTrack}`)
  deleteSlotTrack!: (trackSlot: TrackSlotDraggable) => Promise<void>;

  @Getter(`${Namespace.Switchboard}/${SwitchboardGetter.GetSlotTracks}`)
  readonly getSlotTracks!: TrackSlot[];

  @Watch("activeConferences", { immediate: true, deep: true })
  onActiveConferencesChange() {
    this.activeConferences?.forEach((activeConference) => {
      for (const conferenceSlotAssignment in activeConference.slotAssignments
        .slotTracks) {
        const track =
          activeConference.slotAssignments.slotTracks[conferenceSlotAssignment];
        if (
          track.slotId === this.slotId &&
          track.trackKind === this.trackKind &&
          activeConference.id === this.conferenceId
        ) {
          if (this.trackSlot) {
            this.trackSlot = [track];
          }
        }
      }
    });
  }

  @Watch("trackSlot", { immediate: true })
  onSlotTrackChange(track: TrackSlotDraggable[]) {
    this.enabledSlotTrack = track.length < 1;
    if (track[0]) {
      this.trackData = {
        trackInfoId: track[0].id ?? track[0].trackInfoId,
        trackKind: track[0].kind ?? track[0].trackKind,
        peerId: track[0].peerId,
        peerName: track[0].peerName,
        conferenceId: this.conferenceId,
        slotId: this.slotId,
      };
      this.pushSlotTrack(this.trackData);
    }
  }

  trackSlot: TrackSlotDraggable[] = [];
  trackData: TrackSlotDraggable = {};

  enabledSlotTrack = true;

  get compareTypes(): boolean {
    if (this.currentItemType !== 0) {
      return this.currentItemType !== this.trackKind;
    }
    return false;
  }

  setCurrentItemType(kind: number) {
    this.$emit("setCurrentItemType", kind);
    this.deleteSlotTrack(this.trackData);
    this.enabledSlotTrack = true;
    if (kind === 0 && this.trackSlot[0]) {
      this.pushSlotTrack(this.trackData);
    }
  }

  closeAction() {
    this.$emit("close");
    this.deleteSlotTrack({ ...this.trackData, slotId: this.slotId });
    this.enabledSlotTrack = true;
    this.trackSlot = [];
  }
}
