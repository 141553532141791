









import PageTemplate from "@/components/PageTemplate.vue";
import SwitchboardOverview from "@/components/Switchboard/SwitchboardOverview.vue";
import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";
@Component({
  components: {
    PageTemplate,
    SwitchboardOverview,
  },
})
export default class Switchboard extends Vue {
  RouteName = RouteName;
}
