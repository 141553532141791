













































































































import { Namespace } from "@/store/types";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  Broadcast,
  BroadcastActions,
  BroadcastGetters,
} from "../Broadcast/broadcast/types";
import {
  AssignSlotTrack,
  SwitchboardAction,
  SwitchboardGetter,
  TrackSlotAssignment,
} from "../Switchboard/types";
import draggable from "vuedraggable";
import SwitchboardRoom from "./SwitchboardRoom.vue";
import { Conference, TrackInfo } from "@/spect8-core-vue/src/types";

@Component({
  components: { SwitchboardRoom, draggable },
})
export default class SwitchBoardOverview extends Vue {
  @Action(BroadcastActions.GetBroadcasts)
  fetchBroadcasts!: () => Promise<void>;
  @Action(`${Namespace.Switchboard}/${SwitchboardAction.FetchTrackInfos}`)
  fetchTrackInfos!: () => void;
  @Action(`${Namespace.Switchboard}/${SwitchboardAction.FetchConferences}`)
  fetchConferences!: () => void;
  @Action(`${Namespace.Switchboard}/${SwitchboardAction.ClearSlotTracks}`)
  clearSlotTracks!: () => void;
  @Action(`${Namespace.Switchboard}/${SwitchboardAction.AssignSlotTracks}`)
  assignSlotTracks!: (data: AssignSlotTrack) => Promise<void>;

  @Getter(`${Namespace.Switchboard}/${SwitchboardGetter.GetAllTrackInfos}`)
  readonly trackInfos!: TrackInfo[];
  @Getter(`${Namespace.Switchboard}/${SwitchboardGetter.GetAllConferences}`)
  readonly conferences!: Conference[];
  @Getter(`${Namespace.Switchboard}/${SwitchboardGetter.GetActiveConferences}`)
  readonly activeConferences!: (
    selectedBroadcastId: string | undefined
  ) => Conference[];
  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];
  @Getter(`${Namespace.Switchboard}/${SwitchboardGetter.GetSlotTracks}`)
  readonly getSlotTracks!: TrackSlotAssignment[];

  @Watch("selectedBroadcast")
  onSelectedBroadcastChange(
    newValue: Broadcast | null,
    oldValue: Broadcast | null
  ) {
    if (!this.selectedBroadcast) return;
    if (newValue?.id !== oldValue?.id) {
      this.clearSlotTracks();
    }
  }

  isSaved = false;
  selectedBroadcast: Broadcast | null = null;
  currentItemType = 0;

  get selectedBroadcastId(): string | undefined {
    return this.selectedBroadcast?.id;
  }

  get activeConferencesForBroadcastId(): Conference[] {
    return this.activeConferences(this.selectedBroadcastId);
  }

  get activeConferencesExist(): boolean {
    return this.activeConferencesForBroadcastId?.length !== 0;
  }

  setCurrentItemType(type: number) {
    this.isSaved = false;
    this.currentItemType = type;
  }

  async refresh() {
    this.isSaved = false;
    if (this.selectedBroadcast && this.activeConferencesExist) {
      const broadcast: Broadcast = this.selectedBroadcast;
      this.selectedBroadcast = null;
      this.clearSlotTracks();
      await this.fetchTrackInfos();
      await this.fetchConferences();
      this.selectedBroadcast = broadcast;
    } else {
      await this.fetchTrackInfos();
      await this.fetchConferences();
    }
  }

  async applyChanges() {
    if (this.activeConferencesExist) {
      const data = {
        tenantId: this.activeConferencesForBroadcastId[0].tenantId,
        conferenceIds: this.activeConferences(this.selectedBroadcastId).map(
          (conference: Conference) => conference.id
        ),
        slotId: this.activeConferencesForBroadcastId[0].slotIds,
        removeAllOtherSlotAssignments: true,
        slotAssignments: this.getSlotTracks,
      };
      await this.assignSlotTracks(data);
      await this.refresh();
      this.isSaved = true;
    } else {
      await this.refresh();
    }
  }

  async created() {
    await this.fetchBroadcasts();
    await this.refresh();
    if (this.broadcasts.length) {
      this.selectedBroadcast =
        this.broadcasts.find(
          (broadcast) => broadcast.id === this.selectedBroadcast?.id
        ) || this.broadcasts[0];
    }
  }
}
